import React, { Component } from 'react';
import "./../../components/hero/hero.scss";
import "./../../styles/forms.scss";
import { NavLink } from "react-router-dom";
import  './../../utils/analytics';

export default class Contact extends Component {
  constructor(props) {
      super(props);
      this.state = {
          componentReady: '',
		  Name: '',
		  Email:'',
		  Phone:'',
		  Message:''
      };
  }

  componentDidMount = () => {
    setTimeout(function() {
      this.setState(
				{
					componentReady: 'ready'
				}
		);

    }.bind(this), 10);
  }
  handleChange = (e) => {
	var name = e.target.name;
    this.setState({[name]: e.target.value});
  }
  handleSubmit = (e) => {
	 e.preventDefault();
	 var url = 'http://toolbox.alpine.design/form/contactform.php?'+
	 'Name='+this.state.Name+
	 '&Email='+this.state.Email+
	 '&Message='+this.state.Message+
	 '&RecipientEmail=info@alpine.design'+
	 '&RecipientName=Alpine Design'+
   	 '&SenderName='+this.state.Name+
	 '&SenderEmail='+this.state.Email+
	 '&Source=alpine.design&Meat='+this.state.Phone+
	 '&Success=http://alpine.design/thankyou'+
	 '&Error=http://alpine.design/oops'+
	 '&Subject=Alpine Design Contact Form'+
	 '&Source=Alpine.Design';

     var xhr = new XMLHttpRequest();
	 xhr.open("POST", url, true);

	 //Send the proper header information along with the request
	 xhr.send();
	 document.getElementById('submit-button').click()

  }

  render() {
    return (
      <div className={this.state.componentReady}>
        <div id="hero" className="hero">
  		  <div className="hero-content">
  		    <div className="medium-padding small-max-width-container">
              <h1>Want to chat?</h1>
              <form onSubmit={this.handleSubmit}>
                <div className="tiny-padding">
                  <input type="text" name="Name" placeholder="Name" value={this.state.Name} onChange={this.handleChange}/>
                </div>
                <div className="tiny-padding">
                  <input type="text" name="Email" placeholder="Email" value={this.state.Email} onChange={this.handleChange} />
                </div>
				<div className="tiny-padding" style={{'display':'none'}}>
                  <input type="text" name="Phone" placeholder="Phone" value={this.state.Phone} onChange={this.handleChange} />
                </div>
                <div className="tiny-padding">
                  <textarea type="text" name="Message" placeholder="Message" value={this.state.Message} rows="5" onChange={this.handleChange} ></textarea>
                </div>
                <div className="tiny-padding">
                  	<input type="submit" className="primary-background white" value="Submit" />
                </div>
              </form>
            </div>
		</div>
		<div id="hero-background" className="hero-background" style={{backgroundImage: "url(images/contact-back.jpg)"}}></div>
		<NavLink id="submit-button" to="/thankyou"></NavLink>
	   </div>
      </div>
    );
  }
}
