import React, { Component } from 'react';
import "./hero.scss";

export default class Hero extends Component {
  constructor(props) {
      super(props);
      this.state = {
          componentReady: ''
      };
  }

  componentDidMount = () => {
    setTimeout(function() {
      this.setState(
				{
					componentReady: 'ready'
				}
		);

    }.bind(this), 10);
  }
  render() {
    return (
      <div className={this.state.componentReady}>
        <div className={`hero ${ this.props.height }`}>
		  <div id="hero-parallax" className="hero-content">
			<h1 className="hero-title full-width fancy-font">{this.props.title}</h1>
      <h4 className="hero-subtitle uppercase full-width">{this.props.subtitle}</h4>
      {this.props.poweredByImage &&
        <div className="powered-by-stamp"><img src={this.props.poweredByImage} /></div>
      }
		  </div>
		 <div className="hero-background" style={{backgroundImage: "url(" + this.props.backgroundImage + ")"}}></div>
		</div>
      </div>
    );
  }
}
