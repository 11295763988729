import React, { Component } from "react";
import * as Animated from "animated/lib/targets/react-dom";
import {Helmet} from "react-helmet";
import TransitionGroup from "react-transition-group/TransitionGroup";

const routes = [
	{
		path:'/',
		title:'Beach RV Park - Kennewick, Tri Cities, Richland, WA',
		description:'Kennewick, Tri Cities, Richland, WA',
		canonical:'',
	},
	{
		path:'/contact',
		title:'Contact',
		description:'Kennewick, Tri Cities, Richland, WA',
		canonical:'',
	},
	{
		path:'/rates',
		title:'Rates',
		description:'Kennewick, Tri Cities, Richland, WA',
		canonical:'',
	},
	{
		path:'/amenities',
		title:'Amenities',
		description:'Kennewick, Tri Cities, Richland, WA',
		canonical:'',
	},
	{
		path:'/blog',
		title:'Blog',
		description:'Kennewick, Tri Cities, Richland, WA',
		canonical:'',
	},
	{
		path:'/gallery',
		title:'Gallery',
		description:'Kennewick, Tri Cities, Richland, WA',
		canonical:'',
	}
]

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount = () =>{
		this.checkPath()
	}
	componentDidUpdate = () =>{
		this.checkPath()
	}
	checkPath = () =>{
		let path = this.props.location.pathname;
		if(path != this.state.path){
			let title = routes[0].title;
			let description = routes[0].description;
			let canonical = routes[0].canonical;
			for (let i = 0; i < routes.length; i++) {
				if(routes[i].path == path){
					title = routes[i].title
					description = routes[i].description
					canonical = routes[i].canonical
				}
			}
			this.setState({
				title:title,
				description:description,
				canonical:canonical,
				path:path
			})
		}
	}

	render() {
		let title = this.state.title;
		let description = this.state.description;
		let canonical = this.state.canonical;
		return (
			<div>
				<Helmet>
						{title && <title>{title}</title>}
						{description && <meta name="description" content={description} />}
						{canonical && <link rel="canonical" href={canonical} />}
				</Helmet>
			</div>
		);
	}
};
