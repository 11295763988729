import React, { Component } from "react";
import Hero from './../../components/hero';
import { Link } from "react-router-dom";
import  './../../utils/analytics';
import  {Colors} from './../../utils/variables';
import ScrollFadeIn from './../../components/scrollFadeIn';

export default class extends Component {
  componentDidMount = ()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
		<div className="light-grey-background" style={{backgroundColor:Colors.primaryDark}}>
      <div>
        <Hero
          title={'Forms & Documents'}
          backgroundImage={'/images/about-us-collage-2.jpg'}
          height={'half'} /*full, half,threequarter*/
          tint={''}
        />
      </div>
      <ScrollFadeIn>
        <div className="medium-max-width-container lip" style={{paddingBottopm:'8vh'}}>
          <div className="row sponsor-row">
            <div className="small-12 medium-4 tiny-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="./pdfs/photo-release.pdf" style={{textDecoration:'none'}}><h2 className="white large-padding" style={{paddingLeft:0, paddingRight:0}}>{'Photo Release'}</h2></a>
              </div>
            </div>
            <div className="small-12 medium-4 tiny-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="./pdfs/health-form.pdf" style={{textDecoration:'none'}}><h2 className="white large-padding" style={{paddingLeft:0, paddingRight:0}}>{'Health Form'}</h2></a>
              </div>
            </div>
            <div className="small-12 medium-4 tiny-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="./pdfs/waiver.pdf" style={{textDecoration:'none'}}><h2 className="white large-padding" style={{paddingLeft:0, paddingRight:0}}>{'Waiver'}</h2></a>
              </div>
            </div>
          </div>
        </div>
      </ScrollFadeIn>
    </div>
    );
  }
}
