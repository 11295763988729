import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import ClippedBanger from './../../components/clippedBanger';
import Footer from "./../../components/footer";

export default class Services extends Component {
  render() {
    return (
      <div>
        <ClippedBanger
			title="Message Sent"
            text="A member of our team will contact you shortly to talk more about your project."
			backgroundImage="images/thankyou-back.jpg"
			height="full"
			/>
        <Footer />
      </div>
    );
  }
}
