import React, { Component } from "react";
import Hero from './../../components/hero';
import { Link } from "react-router-dom";
import  './../../utils/analytics';
import  {Colors} from './../../utils/variables';
import ScrollFadeIn from './../../components/scrollFadeIn';
import ParallaxCollage1 from './../../components/parallaxCollage1';
import Collapsible from 'react-collapsible';

export default class extends Component {
  componentDidMount = ()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
		<div className="light-grey-background" style={{backgroundColor:Colors.primaryDark, paddingBottom:'10vh'}}>
      <div className="slant-bottom">
        <Hero
          title={'Why We Ride'}
          subtitle={'Bigger than the Mountain'}
          backgroundImage={'/images/about-hero.jpg'}
          height={'full'} /*full, half,threequarter*/
          tint={''}
        />
      </div>
      <div>
        <div className="gutter">
          <div className="medium-max-width-container story-lip lip shadow" style={{color:Colors.white, backgroundColor:Colors.primary}}>
            <div className="large-padding centered-text" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
              <h1>{'Bigger than the Mountain'}</h1>
              <p className="subheading">{'We are Ann and Amanda, the founders of Peak Pursuits. We are local to South Lake Tahoe and love riding the South Shore trails. Our friendship started on bikes when we realized riding up steep mountains is a lot better together. We will all face impossibly hard life climbs. What if we didn’t have to face them alone? They say the best views come after the hardest climbs. And we agree. So we dreamt up a weekend of riding bikes with a community that sticks with you through it all.'}</p>
              <p>{'So, hold onto hope and pedal hard (pain is temporary but Strava times are forever), but don’t forget to look back over your shoulder because that hill you’re climbing…you’re not climbing it alone.'}</p>
            </div>
          </div>
        </div>
        <div style={{marginTop:'10vh'}}>
          <ScrollFadeIn>
            <ParallaxCollage1
              title={'Amanda'}
              description={'In December of 2023 I wasn’t sure I’d be riding bikes anytime soon. Or ever again? In 2022, I was diagnosed with a brain tumor in my primary movement and speech center of my brain. I had to have an awake craniotomy at UCSF in December 2023 to diagnose the tumor. A metal plate and a handful of screws in my skull later, there is still a lot of uncertainty with my brain and my future. But I’m choosing to make my life’s hardest thing, my life’s best thing.'}
              image1={'/images/amanda-4.png'}
              image2={'/images/amanda-5.jpg'}
              backgroundImage={'/images/about-us-collage-2.jpg'}
            />
          </ScrollFadeIn>
        </div>
        <div className="medium-max-width-container shadow story-lip lip"  style={{marginBottom:'5vh', color:Colors.white,backgroundColor:Colors.primary}}>
        <ScrollFadeIn>
          <div className="row alternating-grid-row" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
            <div className="small-12 medium-6">
              <img className="alternating-grid-image" alt="" src="images/square.png" style={{'backgroundImage':'url(images/amanda-3.jpg)'}} />
            </div>
            <div className="small-12 medium-6 row">
              <div className="alternating-grid-text center-position">
                <p><span className="subheading">{`I don’t have much of a biking resume other than I love it. To me, there is nothing better than taking a map and spreading it out on my kitchen table, tracing contour lines with my finger to peaks and lakes and wondering how to get a bike there. I learned how to ride here in South Lake Tahoe and spent the first few summers covered in bruises and with bloody shins. Every ride, I learn a little bit more and crash a little bit less.`}</span></p>
              </div>
            </div>
          </div>
        </ScrollFadeIn>
        <ScrollFadeIn>
          <div className="row alternating-grid-row reverse" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
            <div className="small-12 medium-6">
              <img className="alternating-grid-image" alt="" src="images/square.png" style={{'backgroundImage':'url(images/about-us-collage-1.jpg)'}} />
            </div>
            <div className="small-12 medium-6 row">
              <div className="alternating-grid-text center-position" style={{color:Colors.white}}>
                <p><span className="subheading">{'It’s freedom to me: pedaling to the saddles and ridges of mountains, and breathing so hard you can feel every rib expand and contract, and soaking sweaty, soggy feet in cold streams cradled by wildflowers, and hearing your inner voice yell at you to stop when you’re so tired, but you just. keep. pedaling…and eventually, that voice stops. And you’re just there. You and the mountains.'}</span></p>
              </div>
            </div>
          </div>
        </ScrollFadeIn>
        <ScrollFadeIn>
          <div className="alternating-grid-row" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
            <div className="alternating-grid-text center-position centered-text">
              <p><span className="subheading">{`I saw my time in the mountains with the people I love dearly *almost* come to an end: and then…it didn’t. Now I am living in a miracle and grateful for more time. Unbelievably grateful. I won’t waste it.`}</span></p>
            </div>
          </div>
        </ScrollFadeIn>
        </div>
        <div style={{marginTop:'10vh'}}>
          <ScrollFadeIn>
            <ParallaxCollage1
              title={'Ann'}
              description={`Hi, my name is Ann and I've been immersing myself in the world of mountain biking for over 15 years.  My journey with mountain biking began as a personal passion with just casually exploring various trails and then this turned into wanting to go further and push my limits.  Over the years, this passion evolved into the desire to share the thrill and freedom of mountain biking with others.`}
              image1={'/images/anna-1.jpg'}
              image2={'/images/anne-6.jpg'}
              backgroundImage={'/images/anna-back.jpg'}
            />
          </ScrollFadeIn>
        </div>
        <div className="medium-max-width-container shadow story-lip lip"  style={{marginBottom:'5vh', color:Colors.white,backgroundColor:Colors.primary}}>
        <ScrollFadeIn>
          <div className="row alternating-grid-row" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
            <div className="small-12 medium-6">
              <img className="alternating-grid-image" alt="" src="images/square.png" style={{'backgroundImage':'url(images/anna-2.jpg)'}} />
            </div>
            <div className="small-12 medium-6 row">
              <div className="alternating-grid-text center-position">
                <p><span className="subheading">{`I have been working in healthcare for the last 14+ years as a Physical Therapist Assistant and at the community college since March 2024.  I currently hold a certification in Wilderness First Aid and have my bachelors in Outdoor Education.  I previously have been an instructor who led youth on overnight mountain bike trips and I have been on countless outdoor adventures.  I aspire to bring riders together to build lasting connections through shared adventures on the trails.`}</span></p>
              </div>
            </div>
          </div>
        </ScrollFadeIn>
        <ScrollFadeIn>
          <div className="alternating-grid-row" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
            <div className="alternating-grid-text center-position centered-text">
              <p><span className="subheading">{`I look forward to seeing this mountain bike gathering evolve to meet the mountain biking needs of the female cyclists in the Tahoe community.`}</span></p>
            </div>
          </div>
        </ScrollFadeIn>
        </div>
      </div>
      <ScrollFadeIn>
        <div className="medium-max-width-container shadow" style={{color:Colors.white, backgroundColor:Colors.primary, marginTop:'5vh'}}>
          <div className="large-padding centered-text" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
            <h1>{'Contact Us'}</h1>
            <p>{'Email: peakpursuitsmtnbiking@gmail.com'}</p>
            <p>{'Instagram: peakpursuitsmtb'}</p>
          </div>
        </div>
      </ScrollFadeIn>
    </div>
    );
  }
}
