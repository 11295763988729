import React, { Component } from "react";
import Hero from './../../components/hero';
import { Link } from "react-router-dom";
import  './../../utils/analytics';
import  {Colors} from './../../utils/variables';
import ScrollFadeIn from './../../components/scrollFadeIn';

export default class extends Component {
  componentDidMount = ()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
		<div className="light-grey-background" style={{backgroundColor:Colors.primaryDark}}>
      <div className="slant-bottom">
        <Hero
          title={'Sponsors'}
          subtitle={'Check out our favorite humans and their passions'}
          backgroundImage={'/images/about-us-collage-2.jpg'}
          height={'full'} /*full, half,threequarter*/
          tint={''}
        />
      </div>
      <ScrollFadeIn>
        <div className="medium-max-width-container lip" style={{paddingBottopm:'8vh'}}>
          <div className="row sponsor-row">
            <div className="small-12 medium-12 small-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="https://www.ibiscycles.com/"><img className="half-width" src="/images/logo-ibis.png" /></a>
              </div>
            </div>
            <div className="small-12 medium-6 small-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="https://southshorebikestahoe.com"><img className="half-width" src="/images/logo-southshorebikes.png" /></a>
              </div>
            </div>
            <div className="small-12 medium-6 small-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="https://shorelineoftahoe.com/"><img className="half-width" src="/images/logo-shoreline.png" /></a>
              </div>
            </div>
            <div className="small-12 medium-6 small-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="https://drinkcoffeedostuff.com/"><img className="half-width" src="/images/logo-drinkcoffeedostuff.png" /></a>
              </div>
            </div>
            <div className="small-12 medium-6 small-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="https://www.pitviper.com"><img className="half-width" src="/images/logo-pit-viper.png" /></a>
              </div>
            </div>
            <div className="small-12 medium-6 small-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="https://www.sockguy.com"><img className="half-width" src="/images/logo-sock-guy.png" /></a>
              </div>
            </div>
            <div className="small-12 medium-6 small-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="https://skida.com/"><img className="half-width" src="/images/logo-skida.png" /></a>
              </div>
            </div>
            <div className="small-12 medium-6 small-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="https://www.sierrasoapboxcandleco.com/"><img className="half-width" src="/images/logo-soapbox.png" /></a>
              </div>
            </div>
            <div className="small-12 medium-6 small-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="https://bikemindedtahoe.com/"><img className="half-width" src="/images/logo-bike-minded.png" /></a>
              </div>
            </div>
            <div className="small-12 medium-6 small-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="https://wattabike.com/"><img className="half-width" src="/images/logo-wattabike.png" /></a>
              </div>
            </div>
            <div className="small-12 medium-6 small-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="https://www.alpine.design"><img className="half-width" src="/images/logo-alpine.png" /></a>
              </div>
            </div>
            <div className="small-12 medium-6 small-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="https://www.bartonhealth.org/health-services/rehabilitation"><img className="half-width" src="/images/logo-barton.png" /></a>
              </div>
            </div>
            <div className="small-12 medium-6 small-padding">
              <div className="card centered-text"  style={{ backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
                <a target="_blank" href="https://www.instagram.com/fuellabnutrition"><img className="half-width" src="/images/logo-fuellab.png" /></a>
              </div>
            </div>
          </div>
        </div>
      </ScrollFadeIn>
    </div>
    );
  }
}
