import React, { Component } from "react";
import Hero from './../../components/hero';
import { Link } from "react-router-dom";
import  './../../utils/analytics';
import  {Colors} from './../../utils/variables';
import ScrollFadeIn from './../../components/scrollFadeIn';
import Collapsible from 'react-collapsible';

export default class extends Component {
  componentDidMount = ()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
		<div className="light-grey-background" style={{backgroundColor:Colors.primaryDark}}>
      <div className="slant-bottom">
        <Hero
          title={'Free to Roam'}
          poweredByImage="/images/powered-by-ibis.png"
          //subtitle={'Where You Look is Where You Go'}
          backgroundImage={'/images/event-hero.jpg'}
          height={'full'} /*full, half,threequarter*/
          tint={''}
        />
      </div>
      <div>
        <div className="gutter">
          <div className="medium-max-width-container lip shadow" style={{color:Colors.white, backgroundColor:Colors.primary}}>
            <div className="large-padding centered-text" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
              <h1 className="oversized-text">{'Sep 27 - Sep 29'}</h1>
              <p>{'This is what a weekend away on your bike, with like minded people is all about.  This weekend getaway will be an exhilarating blend of adventure and community on and off the trails with all of us doing hard things together and then celebrating our achievements over trail talks, campfires and cool raffles.  Participants can look forward to group rides, ranging from challenging uphills to thrilling downhill descents, ensuring a memorable experience for everyone.  Alongside the biking adventures, the weekend features the happiest of happy hours, wellness and bike maintenance talks, a trail building workshop on Kirkwood trails and an endless amount of time to connect with fellow riders. This is sure to be an amazing weekend that you won’t want to miss.'}</p>
              <div className="small-max-width-container">
                <h6 className="subheading">{`Please note:  At this time, this event is not intended for brand new/unskilled mtb riders due to technical trails and our small team's limited resources to properly and safely support new mtb riders.`}</h6>
              </div>
            </div>
          </div>
          <div className="medium-max-width-container shadow"  style={{marginTop:'5vh', marginBottom:'5vh', color:Colors.white, backgroundColor:Colors.primary}}>
            <ScrollFadeIn>
              <div className="row alternating-grid-row" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
                <div className="small-12 medium-6">
                  <img className="alternating-grid-image" alt="" src="images/square.png" style={{'backgroundImage':'url(images/event-1.jpg)'}} />
                </div>
                <div className="small-12 medium-6 row">
                  <div className="alternating-grid-text center-position">
                    <h2>{"FRIDAY Sep 27"}</h2>
                    <p><b>{'4:00-6:00pm'}</b><span className="subheading">{' // Check in and welcome!'}</span></p>
                    <p><b>{'6:45-7:45pm'}</b><span className="subheading">{' // Guest speaker - Cassie an adventure athlete and avid mountain biker from High Fives Foundation'}</span></p>
                    <p><span className="subheading">{'Ongoing welcome party with the Ibis crew! We will offer light bites and a cash bar. '}</span></p>
                  </div>
                </div>
              </div>
            </ScrollFadeIn>
            <ScrollFadeIn>
              <div className="row alternating-grid-row reverse" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
                <div className="small-12 medium-6">
                  <img className="alternating-grid-image" alt="" src="images/square.png" style={{'backgroundImage':'url(images/event-2.jpg)'}} />
                </div>
                <div className="small-12 medium-6 row">
                  <div className="alternating-grid-text center-position" style={{color:Colors.white}}>
                    <h2>{"SATURDAY Sep 28"}</h2>
                    <p><b>{'7:00am-9:00am'}</b><span className="subheading">{' // Breakfast'}</span></p>
                    <p><b>{'8:30am-9:00am'}</b><span className="subheading">{' // Guest speaker - Kiley from Fuel Lab'}</span></p>
                    <p><b>{'9:30am'}</b><span className="subheading">{' // Group Ride - Pack your water, snacks, and lunch we’re riding all day.'}</span></p>
                    <p><b>{'1:30pm-5:00pm'}</b><span className="subheading">{' // Ibis Bike Demos (Reservation only)'}</span></p>
                    <p><b>{'5:00pm'}</b><span className="subheading">{' // Dinner'}</span></p>
                    <p><b>{'6:00pm'}</b><span className="subheading">{' // Dun, Dun Duuuunnnn! (Dramatic sound effect) '}</span>{'The Raffle!'}</p>
                    <p><b>{'6:30pm'}</b><span className="subheading">{' // Wellness Talk with epic adventurer, entrepreneur, and public speaker, Kat Medina, author of The Joys of Jet Lag'}</span></p>
                    <p><b>{'7:45pm'}</b><span className="subheading">{' // Bike Maintenance Talk with Troy from Southshore Bikes'}</span></p>
                  </div>
                </div>
              </div>
            </ScrollFadeIn>
            <ScrollFadeIn>
              <div className="row alternating-grid-row" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
                <div className="small-12 medium-6">
                  <img className="alternating-grid-image" alt="" src="images/square.png" style={{'backgroundImage':'url(images/event-3.jpg)'}} />
                </div>
                <div className="small-12 medium-6 row">
                  <div className="alternating-grid-text center-position" style={{color:Colors.white}}>
                    <h2>{"SUNDAY Sep 29"}</h2>
                    <p><b>{'7am-9am'}</b><span className="subheading">{' // Breakfast'}</span></p>
                    <p><b>{'9am-1pm'}</b><span className="subheading">{' // Dig Party on Kirkwood Trails! Pack a lunch with a lot of snacks! '}</span></p>
                    <p><b>{'1pm-4pm'}</b><span className="subheading">{' // Group Ride'}</span></p>
                  </div>
                </div>
              </div>
            </ScrollFadeIn>
          </div>
        </div>
        <ScrollFadeIn>
          <div className="large-padding" style={{color:Colors.white, backgroundColor:Colors.primary}}>
            <h1 className="centered-text">{'FAQ'}</h1>
            <div className="medium-max-width-container shadow" style={{color:Colors.white, backgroundColor:Colors.primaryDark}}>
              <Collapsible trigger="What is included in my weekend registration?">
                <ul className="subheading">
                  <li>{'Breakfast each morning and dinner Saturday evening'}</li>
                  <li>{'2 group rides, shuttles, bike patrol for medical support'}</li>
                  <li>{'1 very rewarding half day of digging trails'}</li>
                  <li>{'Wellness/bike maintenance sessions'}</li>
                  <li>{'A chance to demo Ibis mountain bikes!'}</li>
                </ul>
              </Collapsible>
              <Collapsible trigger="What is NOT included in my weekend registration?">
                <ul className="subheading">
                  <li>{'Lodging is not included.'}</li>
                  <li>{'Lunch is not included.'}</li>
                  <li>{'Alcohol is not included.'}</li>
                </ul>
              </Collapsible>
              <Collapsible trigger="What to bring?">
                <ul className="subheading">
                  <li>{'A freshly tuned, comfortable to you bike'}</li>
                  <li>{'backpack/hip pack'}</li>
                  <li>{'Helmet, full finger gloves, glasses'}</li>
                  <li>{'water, plenty of snacks, and lunch'}</li>
                  <li>{'spare tube and supplies to fix a flat'}</li>
                  <li>{'rain jacket'}</li>
                  <li>{'shirt and extra layers as needed'}</li>
                  <li>{'bike shoes/socks'}</li>
                  <li>{'bike pants/shorts, chamois'}</li>
                  <li>{'knee pads'}</li>
                  <li>{'post ride comfortable clothing'}</li>
                  <li>{'snacks, lots of snacks'}</li>
                </ul>
              </Collapsible>
              <Collapsible trigger="What should my skill level be, am I a good fit?">
                <p className="subheading">{'Our long ride on Saturday will be about 3,500 feet of climbing on technical terrain over 18 miles.  If you feel you can successfully complete a ride of this caliber, in a slow, but steady pace then you will be a great fit!'}</p>
              </Collapsible>
              <Collapsible trigger="Are your retreats e-bike friendly?">
                <p className="subheading">{'We love e-bikes!  Due to the small nature of our retreat and to ensure a consistent experience for all participants, at this time we will not allow e-bikes at our retreats.'}</p>
              </Collapsible>
              <Collapsible trigger="What is your cancellation/refund policy?">
                <p className="subheading">{'Once you sign up we work extremely hard to make sure this will be an incredibly memorable and worthwhile weekend. If you cancel, we will honor a full refund (minus processing fee) up until 30 days out from the event. After this time frame, you will not receive a refund. We plan to ride rain or shine. In the event, conditions are unsafe we will reschedule the event and your registration will be honored at a future event.'}</p>
              </Collapsible>
              <Collapsible trigger="How old do I have to be to attend this event?">
                <p className="subheading">{'Our Women’s Weekend event is for riders who are 18 years or older.'}</p>
              </Collapsible>
              <Collapsible trigger="Do you offer any lodging options?">
                <p className="subheading">{'Stay with the group at Kirkwood resort with Peak Pursuits crew with negotiated block rates. Use the code '}<b>{'SKWPPM24'}</b>{' or the group name Peak Pursuits Mountain Biking for discounted rate. If the internet is not your thing..... call (855) 948-0694 to make your reservation on your rotary telephone'}</p>
              </Collapsible>
            </div>
          </div>
        </ScrollFadeIn>
        <div className="slant-top large-padding centered-text" style={{color:Colors.white}}>
          <ScrollFadeIn>
            <h1>{'Safety'}</h1>
            <div className="small-max-width-container">
              <p className="subheading">{'Peak Pursuits Mountain Biking will prioritize your safety throughout our weekend together. Minimizing the risk of injuries and accidents will be of the utmost importance to Peak Pursuits Mountain Biking.  A few key points to remember:  always wear appropriate safety gear, perform pre-ride checks, know your limits, practice trail etiquette, and stay hydrated and fueled. With these precautions in place, we will hit the trails with confidence and peace of mind, ready to embrace the adventure that awaits. '}</p>
              <div className="small-padding" style={{paddingBottom:0}}>
                <h2 className="fancy-font">{'Happy riding!'}</h2>
              </div>
            </div>
          </ScrollFadeIn>
        </div>
        <div>
          <ScrollFadeIn>
            <div className="shadow" style={{color:Colors.white, backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
              <div className="large-padding centered-text">
                <h1>{'Lets Do This Thing'}</h1>
                <p className="cursive-text subheading">{'Space is limited to 25 people, register today to save your spot'}</p>
                <Link to="/register">
                  <div className="card-button button" style={{color:Colors.white, backgroundColor:Colors.tertiary}}>
                    {'Register Today'}
                  </div>
                </Link>
              </div>
            </div>
          </ScrollFadeIn>
        </div>
      </div>
    </div>
    );
  }
}
