
export const Colors = {
  primary: '#337F97',
  primaryDark: '#206074',
  secondary: '#F6B802',
  tertiary: '#F35B05',
  darkGrey: '#222',
  mediumGrey: '#999',
  lightGrey: '#f9f9f9',
  black: '#000',
  white: '#fff',
  green: '#4cd964',
  red: '#ff2d55',
}
