import React, { Component } from 'react';
import "./countdown.scss";

let x;

export default class Countdown extends Component {
  constructor(props) {
      super(props);
      this.state = {
          componentReady: ''
      };
  }

  componentDidMount = () => {
    this.initializeCountdown(this.props.time)
    setTimeout(() => {
      this.setState(
				{
					componentReady: 'ready'
				}
		);

    }, 10);
  }

  componentWillUnMount = () =>{
    clearInterval(x);
  }

  initializeCountdown = (d) => {
    let countDownDate = new Date(d).getTime();
    x = setInterval(()=> {
      let now = new Date().getTime();
      let distance = countDownDate - now;
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days:days,
        hours:hours,
        minutes:minutes,
        seconds:seconds
      })
    }, 1000);
  }
  render() {
    return (
      <div className={this.state.componentReady}>
        <div className="countdown-container slant-bottom" style={{zIndex:2, backgroundColor:this.props.backgroundColor}}>
          <div className="countdown-title" style={{color:this.props.titleColor}}>{this.props.title}</div>
          <div className="row medium-max-width-container" style={{color:this.props.textColor}}>
            <div className="small-4 medium-3 countdown-tile">
              <div className="countdown-large-text">{this.state.days}</div>
              <div className="countdown-small-text">Days</div>
            </div>
            <div className="small-4 medium-3 countdown-tile">
              <div className="countdown-large-text">{this.state.hours}</div>
              <div className="countdown-small-text">Hours</div>
            </div>
            <div className="small-4 medium-3 countdown-tile">
              <div className="countdown-large-text">{this.state.minutes}</div>
              <div className="countdown-small-text">Minutes</div>
            </div>
            <div className="hide-for-small small-3 medium-3 countdown-tile">
              <div className="countdown-large-text">{this.state.seconds}</div>
              <div className="countdown-small-text">Seconds</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
