import React, { Component } from 'react';
import "./videoHero.scss";
import YoutubeBackground from 'react-youtube-background'
import { Parallax } from 'react-scroll-parallax';

export default class VideoHero extends Component {
  constructor(props) {
      super(props);
      this.state = {
          componentReady: ''
      };
  }

  componentDidMount = () => {
    let aspectRatio = "16:9";
    console.log(window.innerWidth)
    if (window.innerWidth > 800){
      aspectRatio = "1:1"
    }
    this.setState({
      aspectRatio:aspectRatio
    });
    setTimeout(() =>{
      this.setState({
        componentReady: 'ready'
      })
    },1500)
  }
  render() {
    let classStyles = 'video-hero-container';
    if(this.state.componentReady){
      classStyles = 'ready video-hero-container';
    }
    return (
      <div style={{position:'relative'}} className={classStyles}>
        <YoutubeBackground
          aspectRatio={this.state.aspectRatio}
          videoId={this.props.video}>
          <div className={`video-hero hero ${ this.props.height }`}>
            <div id="hero-parallax" className="hero-content">
      		    <h4 className="hero-subtitle uppercase full-width">{this.props.subtitle}</h4>
      			  <h1 className="hero-title full-width">{this.props.title}</h1>
              <Parallax translateY={[-5, 5]}>
                <div className="vertical-tag right">{this.props.verticalText}</div>
              </Parallax>
              {this.props.poweredByImage &&
                <div className="powered-by-stamp"><img src={this.props.poweredByImage} /></div>
              }

      		  </div>
    		  </div>
        </YoutubeBackground>
      </div>
    );
  }
}
