import React, { Component } from 'react';
import "./epicCardGrid.scss";
import { Link } from "react-router-dom";
import { Parallax } from 'react-scroll-parallax';

export default class epicCardGrid extends Component {
  render() {
    return (
      <div className="epic-card-grid-container" style={{backgroundImage:'url('+this.props.backgroundTexture+')', backgroundColor:this.props.backgroundColor}}>
        <div className="epic-card-grid-foreground">
          <div className="card" style={{backgroundColor:this.props.cardBackgroundColor}}>
            <div className="card-title" style={{color:this.props.titleColor}}>{this.props.title}</div>
            <div className="card-description subheading" style={{color:this.props.descrptionColor}}><p>{this.props.description}</p></div>
            <Link to={this.props.link}>
              <div className="card-button button" style={{color:this.props.buttonTextColor, backgroundColor:this.props.buttonBackgroundColor}}>
                {this.props.buttonText}
              </div>
            </Link>
          </div>
        </div>
        <div className="epic-card-grid-background">
          <div className="row">
            <div className="small-6 medium-6">
              <Parallax translateY={[-5, 25]}>
                <div className="parallax-image-1" style={{backgroundImage:'url('+this.props.parallaxImage1+')'}}></div>
              </Parallax>
            </div>
            <div className="small-6 medium-6">
              <Parallax translateY={[-10, 10]}>
                <div className="parallax-image-2" style={{backgroundImage:'url('+this.props.parallaxImage2+')'}}></div>
              </Parallax>
            </div>
            <div className="small-6 medium-6">
              <Parallax translateY={[-20, 5]}>
                <div className="parallax-image-3" style={{backgroundImage:'url('+this.props.parallaxImage3+')'}}></div>
              </Parallax>
            </div>
            <div className="small-6 medium-6">
              <Parallax translateY={[-15, 5]}>
                <div className="parallax-image-4" style={{backgroundImage:'url('+this.props.parallaxImage4+')'}}></div>
              </Parallax>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
