import React, { Component } from "react";
import BangerText from './../../components/bangerText';
import FeaturedCompanies from './../../components/featuredCompanies';
import VideoHero from './../../components/videoHero';
import Countdown from "./../../components/countdown";
import Footer from "./../../components/footer";
import SideBySideCardAndImage from "./../../components/sideBySideCardAndImage";
import EpicCardGrid from "./../../components/epicCardGrid";
import KnockoutComp from "./../../components/knockoutComp";
import ParallaxCollage1 from "./../../components/parallaxCollage1";
import ScrollHighlightText from "./../../components/scrollHighlightText";
import  './../../utils/analytics';
import {Colors} from './../../utils/variables';

export default class Home extends Component {
  componentDidMount = ()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
		<div>
			<VideoHero
				subtitle="Peak Pursuits"
				title={['Dirt, sweat, ',<br />, 'and adventure']}
				video="WEysq3a9qPo"
				height="full"
        verticalText='39.0968° N, 120.0324° W'
        poweredByImage="/images/powered-by-ibis.png"
			/>
      <div className="countdown-section">
        <Countdown
          title=""
          time="Sep 27, 2024 15:37:25"
          titleColor={Colors.white}
          textColor={Colors.white}
          backgroundColor={Colors.primary}/>
      </div>
      <div className="home-page-slant-shim countdown-section">
        <SideBySideCardAndImage
          title="September 27th - 29th 2024"
          description="Join Peak Pursuits for a women’s mountain biking retreat based in the Sierras. Spend the weekend on adventure rides with big climbs and fun downhills, community, and  wellness talks at the base of the mountains at Kirkwood Mountain Resort. You can plan to connect with like-minded individuals while ripping around on some of the most scenic trails in the area."
          buttonText="Learn More"
          image="/images/event-teaser.jpg"
          link="/event"
          buttonTextColor={Colors.white}
          buttonBackgroundColor={Colors.tertiary}
          backgroundColor={Colors.primaryDark}
          backgroundTexture="/images/topo-texture.png"
          cardBackgroundColor={Colors.primary}
          titleColor={Colors.white}
          descriptionColor={Colors.white}
          verticalText='RIDER || ANNA P'
        />
      </div>
      <KnockoutComp
        knockoutText="peak"
        knockoutOverlayImage="/images/knockout-overlay.png"
        title="The Steeper the Climb, the Better the Views"
        knockoutTextBackground="/images/event-hero.jpg"
        description={`It’s always steepest before the summit. But, if we just keep pedaling, we can turn life’s hardest things into life’s best things.
Because all mountains feel big, all uphill climbs hurt, and all peak pursuits are hard:`}
        cursiveText={'Do. It. Anyway'}
        buttonText="learn more"
        buttonTextColor={Colors.white}
        buttonBackgroundColor={Colors.tertiary}
        backgroundColor={Colors.primary}
        link="/story"
        textColor={Colors.white}
        verticalText='RIDER || AMANDA P'
      />
      <EpicCardGrid
        title="Never Easier, just Faster"
        description="Adventure isn’t clean and you won’t be either after a weekend riding bikes with us. The pursuit of summits, the ones on and off the bike, can get a bit messy. . But that’s where we come in, as a hand extended when you feel like quitting. Community in the hard places: it’s the secret to the summit. "
        buttonText="Our Story"
        link="/story"
        buttonTextColor={Colors.white}
        buttonBackgroundColor={Colors.tertiary}
        backgroundColor={Colors.primaryDark}
        backgroundTexture="/images/topo-texture.png"
        cardBackgroundColor={Colors.primary}
        titleColor={Colors.white}
        descriptionColor={Colors.white}
        parallaxImage1='/images/about-us-collage-1.jpg'
        parallaxImage2='/images/about-us-collage-2.jpg'
        parallaxImage3='/images/about-us-collage-3.jpg'
        parallaxImage4='/images/about-us-collage-4.jpg'
      />
		</div>
    );
  }
}
