
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import FullWidthPortfolioTeaser from '../components/fullWidthPortfolioTeaser';
var Projects = require ("../data/projects.json");

export default class FeaturedCompanies extends Component {
  constructor(props) {
      super(props);
      this.state = {
  			projects: Projects,
  		};
  }
  render() {
    return (
      <div className="company-list">
        {this.state.projects.map((p, i) => {
          let direction = "left";
          if(i % 2 != 0){
            direction = "right";
          }
          return (
            <div key={i} className="tiny-padding">
              <FullWidthPortfolioTeaser
                direction={direction}
                title={p.title}
                icon={p.icon}
                description={p.description}
                backgroundImage={p.backgroundImage}
                link={`/${p.id}`}
               />
            </div>
          );
        })}
      </div>
    );
  }
}
