
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './fullWidthPortfolioTeaser.scss';
import ScrollFadeIn from './../scrollFadeIn';
import { Parallax } from 'react-scroll-parallax';

class FullWidthPortfolioTeaser extends Component {
  render() {
    return (
      <ScrollFadeIn>
        <div className={"full-width-portfolio-teaser-container background-image-fill "+this.props.direction} style={{backgroundImage:"url("+this.props.backgroundImage+")"}}>
          <div className="full-width-portfolio-teaser-card-container">
            <Parallax y={[-40, 40]}>
              <div className="full-width-portfolio-teaser-card">
              {this.props.icon && <img alt="" className="full-width-portfolio-teaser-logo" src={this.props.icon} />}
              <h6 className="full-width-portfolio-teaser-title" style={{'marginTop':'0px', textTransform:"uppercase"}}>{this.props.title}</h6>
              <h2 className="subheading full-width-portfolio-teaser-description">{this.props.description}</h2>
              <Link to={this.props.link}>
                <div className="full-width-portfolio-teaser-button"></div>
              </Link>
              </div>
            </Parallax>
          </div>
        </div>
      </ScrollFadeIn>
    );
  }
}

export default FullWidthPortfolioTeaser;
