import React, { Component } from 'react';
import "./stickyCountdown.scss";

let y;

export default class StickyCountdown extends Component {
  constructor(props) {
      super(props);
      this.state = {
          componentReady: '',
          active:false
      };
  }

  componentDidMount = () => {
    this.initializeCountdown(this.props.time)
    window.addEventListener('scroll', this.handleScroll);

  }

  componentWillUnMount = () =>{
    clearInterval(y);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () =>{
    console.log(window.scrollY)
    if(window.scrollY > 500){
      if(!this.state.active){
        this.setState({
          active:true
        })
      }
    } else{
        if(this.state.active){
          this.setState({
              active:false
          })
        }
    }
  }

  initializeCountdown = (d) => {
    let countDownDate = new Date(d).getTime();
    y = setInterval(()=> {
      let now = new Date().getTime();
      let distance = countDownDate - now;
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days:days,
        hours:hours,
        minutes:minutes,
        seconds:seconds
      })
    }, 1000);
  }
  render() {
    let activeClass= this.state.active ? "sticky-countdown-dock active" : "sticky-countdown-dock";
    console.log(activeClass)
    return (
      <div className={this.state.componentReady}>
        <div className="sticky-countdown-container" style={{zIndex:200}}>
          <div className={activeClass}>
            <div className="countdown-title" style={{color:this.props.titleColor}}>{this.props.title}</div>
            <div className="row medium-max-width-container" style={{color:this.props.textColor}}>
              <div className="small-3 medium-3 countdown-tile">
                <div className="countdown-large-text">{this.state.days}</div>
                <div className="countdown-small-text">Days</div>
              </div>
              <div className="small-3 medium-3 countdown-tile">
                <div className="countdown-large-text">{this.state.hours}</div>
                <div className="countdown-small-text">Hours</div>
              </div>
              <div className="small-3 medium-3 countdown-tile">
                <div className="countdown-large-text">{this.state.minutes}</div>
                <div className="countdown-small-text">Minutes</div>
              </div>
              <div className="small-3 medium-3 countdown-tile">
                <div className="countdown-large-text">{this.state.seconds}</div>
                <div className="countdown-small-text">Seconds</div>
              </div>
            </div>

            <div className="bg">
                <div className="mountain">
                  <div className="mountain-top">
                    <div className="mountain-cap-1"></div>
                    <div className="mountain-cap-2"></div>
                    <div className="mountain-cap-3"></div>
                  </div>
                </div>
                <div className="mountain-two">
                  <div className="mountain-top">
                    <div className="mountain-cap-1"></div>
                    <div className="mountain-cap-2"></div>
                    <div className="mountain-cap-3"></div>
                  </div>
                </div>
                 <div className="mountain-three">
                  <div className="mountain-top">
                    <div className="mountain-cap-1"></div>
                    <div className="mountain-cap-2"></div>
                    <div className="mountain-cap-3"></div>
                  </div>
                </div>
                <div className="mountain-shadow"></div>
              </div>

          </div>



        </div>
      </div>
    );
  }
}
