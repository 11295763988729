import React, { Component } from 'react';
import "./hero/hero.scss";
import "../styles/clippedBanger.scss";

export default class ClippedBanger extends Component {
  constructor(props) {
      super(props);
      this.state = {
          componentReady: ''
      };
  }

  componentDidMount = () => {
    setTimeout(function() {
      this.setState(
				{
					componentReady: 'ready'
				}
		);

    }.bind(this), 10);
  }
  render() {
    return (
      <div className={this.state.componentReady}>
        <div className={`hero ${ this.props.height }`}>
			<div id="hero-parallax" className="hero-content">
			  <h1 className="clipped-banger-title clip-text hero-title">{this.props.title}</h1>
			  <div className="small-max-width-container">
				<h3 className="clipped-banger-text white hero-subtitle">{this.props.text}</h3>
			  </div>
			</div>
			<div className="hero-background-tint"></div>
			<div className="hero-background" style={{backgroundImage: "url(" + this.props.backgroundImage + ")"}}></div>
  		</div>
      </div>
    );
  }
}
