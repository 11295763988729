import "./styles/app.scss";
import React from 'react';
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';
import App from './app';

const renderApp = Component =>
	render(
		<ParallaxProvider>
			<Component />
		</ParallaxProvider>,
		document.getElementById("root")
	);

renderApp(App);
