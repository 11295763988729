import React, { Component } from "react";
import Hero from './../../components/hero';
import { Link } from "react-router-dom";
import  './../../utils/analytics';
import {Colors} from './../../utils/variables';

export default class extends Component {
  componentDidMount = ()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
		<div className="primary-background" style={{backgroundColor:Colors.primaryDark,backgroundImage:'url("/images/topo-texture.png")'}}>
      <div className="large-padding centered-text">
        <div className="small-max-width-container" style={{backgroundColor:Colors.primaryDark,maxWidth:1000,marginTop:80}}>
          <div className="card primary-background" style={{backgroundSize:'cover', backgroundPosition:'center',backgroundImage:'url("/images/about-us-collage-2.jpg")', borderRadius:10, padding:30}}>
            <div className="small-max-width-container" style={{maxWidth:400}}>
              <a style={{textDecoration:'none'}} target="_blank" href="https://square.link/u/i7N4UgmQ">
                <div className="card primary-background" style={{ borderRadius:10, padding:30}}>
                  <img className="full-width" src="./images/free-to-roam.png"/>
                  <h4 className="white" style={{textDecoration:'none'}}>{'Sept 27th-29th 2024'}</h4>
                  <div style={{marginBottom:20,backgroundColor:Colors.tertiary, color:Colors.white}} className="button">Register Now</div>
                  <h6 className="white subheading">{'Space limited to 25 people, early registration is recommended'}</h6>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="small-max-width-container" style={{maxWidth:1000,marginTop:80}}>
          <a style={{textDecoration:'none'}} target="_blank" href="https://be.synxis.com/?adult=1&arrive=2024-09-27&chain=6521&child=0&config=SAKW&currency=USD&depart=2024-09-30&dest=TAHOE&hotel=40075&level=hotel&locale=en-US&promo=SKWPPM24&rooms=1&segment=STUDIO&theme=SUMSAKW">
            <div className="card primary-background" style={{borderRadius:10, padding:30}}>
              <h2 className="white" style={{textDecoration:'none'}}>{'Stay With Us'}</h2>
              <p className="white subheading">{'Stay with the group at Kirkwood resort with Peak Pursuits crew with negotiated block rates. Use the code '}<b>{'SKWPPM24'}</b>{' or the group name Peak Pursuits Mountain Biking for discounted rate. If the internet is not your thing..... call (855) 948-0694 to make your reservation on your rotary telephone'}</p>
              <div style={{marginBottom:20,backgroundColor:Colors.tertiary, color:Colors.white}} className="button">Book Now</div>
            </div>
          </a>
        </div>
      </div>
    </div>
    );
  }
}
