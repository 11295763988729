import React, { Component } from 'react';
import "./parallaxCollage1.scss";
import { Link } from "react-router-dom";
import { Parallax } from 'react-scroll-parallax';

export default class ParallaxCollage1 extends Component {
  render() {
    return (
      <div className="parallax-collage-1 large-padding"  style={{backgroundImage:'url('+this.props.backgroundImage+')'}}>
        <div className="row">
      		<div className="small-12 medium-6 parallax-collage-1-background">
            <Parallax translateY={[-10, 0]}>
              <div className="parallax-collage-1-image-1">
                <img src={this.props.image1} className="full-width" />
              </div>
            </Parallax>
            <Parallax translateY={[-20, 20]}>
              <div className="parallax-collage-1-image-2" style={{backgroundImage:'url('+this.props.image2+')'}}></div>
            </Parallax>
      		</div>
      		<div className="small-12 medium-6 parallax-collage-1-content-container">
      			<div className="parallax-collage-1-content">
      				<h1 className="parallax-collage-title">{this.props.title}</h1>
      				<div className="parallax-collage-description-container">
      					<p className="subheading collage-text">{this.props.description}</p>
      				</div>
      			</div>
      		</div>
        </div>
	    </div>
    );
  }
}
