import React, { Component } from 'react';
import "./sideBySideCardAndImage.scss";
import { Link } from "react-router-dom";
import { Parallax } from 'react-scroll-parallax';

export default class sideBySideCardAndImage extends Component {
  render() {
    return (
      <div className="side-by-side-card-and-image-container slant-bottom" style={{zIndex:1,backgroundColor:this.props.backgroundColor, backgroundImage:'url('+this.props.backgroundTexture+')'}}>
        <div className="full-width side-by-side-card-and-image-inner" >
          <Parallax translateY={[10,50]}>
            <div className="vertical-tag right">{this.props.verticalText}</div>
          </Parallax>
          <Parallax translateY={[-5, 5]}>
            <div className="medium-max-width-container">
              <div className="row">
                <div className="small-12 medium-6" style={{display:'flex', alignItems:'center'}}>
                    <div className="card full-width" style={{backgroundColor:this.props.cardBackgroundColor}}>
                      <div className="card-title" style={{color:this.props.titleColor}}>{this.props.title}</div>
                      <div className="card-description subheading" style={{color:this.props.descrptionColor}}><p>{this.props.description}</p></div>
                      <Link to={this.props.link}>
                        <div className="card-button button" style={{color:this.props.buttonTextColor, backgroundColor:this.props.buttonBackgroundColor}}>
                          {this.props.buttonText}
                        </div>
                      </Link>
                    </div>
                </div>
                <div className="small-12 medium-6">
                  <Parallax translateY={[-5, 5]}>
                    <img className="shadow side-by-side-card-and-image-image full-width" src="/images/square.png" style={{backgroundImage:'url('+this.props.image+')'}} />
                  </Parallax>
                </div>
              </div>
            </div>
          </Parallax>
        </div>
      </div>
    );
  }
}
